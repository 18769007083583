import React from 'react';

import { Icon } from '@types';

const VarsitySocialIcon: Icon = ({ fill = "white" }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="8vw"
    viewBox="0 0 768.000000 113.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,113.000000) scale(0.100000,-0.100000)"
      fill={fill}
      stroke="none"
    >
      <path d="M380 1114 c-177 -47 -314 -180 -361 -352 -91 -326 179 -646 524 -619 186 14 361 143 428 314 33 87 38 230 10 321 -76 250 -349 403 -601 336z m0 -293 c0 -5 -6 -14 -14 -21 -9 -7 -12 -19 -8 -29 18 -47 195 -340 202 -336 4 3 43 66 85 141 74 127 78 137 69 172 -8 32 -15 39 -51 52 -24 8 -43 18 -43 22 0 4 43 8 95 8 52 0 95 -3 95 -7 0 -11 -302 -523 -308 -523 -9 0 -302 505 -302 519 0 7 33 11 90 11 50 0 90 -4 90 -9z" />
      {/* <path d="M1315 898 c-7 -49 -2 -141 6 -118 4 8 10 29 15 45 13 42 63 65 142 65 l62 0 0 -282 c0 -156 -4 -290 -10 -298 -5 -8 -26 -20 -47 -26 -33 -10 -18 -12 112 -12 126 0 144 2 115 12 -63 20 -60 2 -60 317 l0 289 59 0 c32 0 72 -5 90 -11 33 -12 61 -52 61 -89 0 -11 5 -20 11 -20 8 0 10 16 5 53 -3 28 -6 67 -6 85 l0 32 -274 0 -275 0 -6 -42z" /> */}
      {/* <path d="M1935 930 c-3 -6 5 -10 18 -10 14 0 35 -9 48 -19 l24 -19 0 -281 c0 -310 2 -301 -65 -319 -27 -8 0 -10 115 -10 144 0 148 0 109 14 -51 18 -54 26 -54 177 l0 117 150 0 150 0 0 -127 c0 -71 -4 -135 -10 -143 -5 -8 -26 -20 -47 -26 -33 -10 -18 -12 112 -12 130 0 145 2 112 12 -21 6 -42 18 -47 26 -6 8 -10 140 -10 293 0 264 1 278 20 297 11 11 31 20 45 20 14 0 25 5 25 10 0 6 -55 10 -145 10 -90 0 -145 -4 -145 -10 0 -5 10 -10 23 -10 12 0 33 -7 45 -16 21 -14 22 -22 22 -145 l0 -129 -150 0 -150 0 0 125 c0 112 2 127 20 145 11 11 31 20 45 20 14 0 25 5 25 10 0 6 -53 10 -139 10 -83 0 -142 -4 -146 -10z" />
      <path d="M2680 930 c0 -5 11 -10 25 -10 14 0 34 -9 45 -20 19 -19 20 -33 20 -297 0 -153 -4 -285 -10 -293 -5 -8 -26 -20 -47 -26 -31 -10 9 -12 226 -11 l264 2 24 69 c31 93 29 110 -6 54 -46 -72 -68 -82 -188 -86 -56 -3 -114 -1 -128 2 l-25 6 0 140 0 140 98 0 c107 0 142 -13 142 -53 0 -12 5 -29 10 -37 7 -10 10 25 10 108 0 75 -4 122 -10 122 -5 0 -10 -13 -10 -28 0 -57 -18 -67 -134 -70 l-106 -4 0 132 0 132 121 -3 121 -4 29 -33 c16 -18 29 -41 29 -52 0 -10 5 -22 12 -26 8 -5 9 9 5 56 -4 35 -7 71 -7 81 0 18 -13 19 -255 19 -163 0 -255 -4 -255 -10z" />
      <path d="M3510 930 c0 -5 6 -10 14 -10 27 0 64 -40 93 -102 17 -35 79 -174 138 -310 59 -137 111 -248 114 -248 3 0 60 136 125 302 119 301 149 358 190 358 9 0 16 5 16 10 0 11 -192 14 -204 3 -3 -4 8 -12 26 -17 23 -8 34 -18 36 -34 3 -21 -140 -405 -158 -425 -7 -8 -190 400 -190 425 0 21 21 38 47 38 13 0 23 5 23 10 0 6 -52 10 -135 10 -83 0 -135 -4 -135 -10z" />
      <path d="M4355 763 c-209 -483 -194 -453 -239 -473 l-41 -19 115 0 c104 1 112 2 80 13 -46 17 -48 36 -12 127 l30 74 122 0 122 0 30 -74 c34 -88 32 -114 -14 -128 -26 -9 -3 -11 112 -11 117 0 139 2 111 11 -20 6 -42 24 -56 46 -13 20 -78 165 -146 324 -67 158 -126 287 -130 287 -3 0 -41 -80 -84 -177z m102 -101 c23 -53 45 -105 49 -114 6 -16 -3 -18 -96 -18 -56 0 -100 4 -98 8 54 135 93 222 97 222 3 0 25 -44 48 -98z" />
      <path d="M4810 931 c0 -6 11 -11 25 -11 14 0 34 -9 45 -20 19 -19 20 -33 20 -297 0 -153 -4 -285 -10 -293 -5 -8 -26 -20 -47 -26 -33 -10 -20 -12 107 -12 109 -1 139 2 120 10 -57 23 -55 16 -58 162 l-3 136 25 0 c21 0 47 -28 143 -155 l117 -155 91 0 c62 0 85 3 75 10 -8 5 -22 10 -30 10 -9 0 -32 17 -51 38 -40 41 -199 247 -199 256 0 3 18 13 40 22 135 54 144 241 15 308 -36 19 -61 21 -232 25 -137 2 -193 0 -193 -8z m301 -41 c81 -23 124 -122 84 -196 -20 -38 -78 -69 -142 -77 l-43 -5 0 138 c0 136 0 139 23 143 38 7 41 7 78 -3z" />
      <path d="M5619 917 c-52 -35 -79 -82 -79 -138 0 -89 30 -127 164 -209 108 -65 146 -107 146 -161 0 -46 -38 -93 -84 -105 -77 -19 -165 42 -192 132 -8 30 -20 54 -25 54 -5 0 -9 -49 -9 -110 0 -91 3 -110 15 -110 8 0 15 5 15 10 0 15 41 12 90 -5 84 -30 196 -10 242 41 25 28 48 88 48 124 0 41 -25 99 -58 133 -15 16 -67 54 -117 84 -106 65 -129 85 -145 124 -24 58 27 119 100 119 64 0 110 -40 140 -121 12 -32 26 -56 31 -53 10 6 12 214 2 214 -3 0 -14 -7 -23 -15 -14 -13 -22 -13 -64 0 -75 23 -156 20 -197 -8z" />
      <path d="M6025 930 c-3 -6 5 -10 18 -10 14 0 35 -9 48 -19 l24 -19 0 -281 c0 -310 2 -301 -65 -319 -27 -8 0 -10 115 -10 144 0 148 0 109 14 -28 10 -44 23 -48 37 -3 12 -6 142 -6 290 0 254 1 268 20 287 11 11 31 20 45 20 14 0 25 5 25 10 0 6 -53 10 -139 10 -83 0 -142 -4 -146 -10z" />
      <path d="M6374 927 c-3 -8 -4 -47 -2 -88 l3 -74 12 41 c20 64 41 77 133 82 l80 5 0 -274 c0 -151 -3 -284 -6 -296 -4 -14 -20 -27 -48 -37 -39 -14 -35 -14 109 -14 115 0 142 2 115 10 -67 18 -65 9 -65 321 l0 282 77 0 c67 0 80 -3 102 -24 14 -13 29 -40 32 -58 11 -60 22 -21 15 57 l-6 75 -273 3 c-222 2 -273 0 -278 -11z" />
      <path d="M6976 931 c-3 -5 9 -12 27 -15 46 -9 70 -37 181 -216 l96 -154 0 -101 c0 -120 -10 -145 -65 -161 -35 -11 -21 -12 115 -12 119 0 147 2 120 10 -57 16 -62 26 -68 146 l-5 109 87 147 c98 166 143 222 187 231 61 14 25 25 -81 25 -67 0 -110 -4 -110 -10 0 -5 8 -10 18 -10 20 0 42 -18 42 -35 0 -6 -34 -69 -76 -139 -62 -105 -78 -126 -88 -115 -7 8 -43 62 -79 120 -83 132 -86 169 -16 169 11 0 17 5 14 10 -8 13 -292 14 -299 1z" /> */}
    </g>
  </svg>

)

export default VarsitySocialIcon